* {
  box-sizing: border-box;
}

.image-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.image-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.image-delete {
  font-size: 30px;
  color: firebrick;
  position: relative;
  right: 1.5rem;
}

/* Responsive layout -stack on top of each other instead of next to each other */
@media screen and (max-width: 700px) {
  .image-container {
    flex-direction: column;
    max-width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }

  .image-item {
    max-width: 100%;
  }
}
