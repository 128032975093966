.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -25px;
    background-color: var(--text-color-header);
    color: #fff;
    text-align: center;
    border-radius: var(--border-radius);
    padding: 16px;
    position: fixed;
    z-index: 1;
    bottom: 10px;
    font-size: 1rem;
}

.snackbar.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.snackbar.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms
}