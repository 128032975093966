.card {
    display: flex;
    position: relative;
    flex-direction: column;
    background: white;
    border: 1px solid #343637;
    border-radius: var(--border-radius);
}
.card .card-image-container {
    width: 100%;
    height: 20rem;
    overflow: hidden;
    transition: all 0.2s;
}

.card img {
    width: 100%;
    object-fit: cover;
}

.card:hover .card-image-container {
    transform: scale(1.1);
}

.card .card-image-container::before {
    content: "";
    inset: 0;
    width: 100%;
    mix-blend-mode: multiply;
    transform: translateX(-120%) skewX(-10deg);
    transition: transform 0.3s ease-out;
}

.card-footer {
    padding: 2rem 2rem 2rem 2rem;
    transition: all 0.3s ease;
    border-top: 1px solid rgb(238, 238, 238);
}

.card-footer h3 {
    font-size: 2rem;
    font-weight: 500;
}

.card-footer span {
    font-weight: bold;
    font-size: 1.2rem;
    color: #5a6469;
}

.card-footer p {
    font-size: 1.5rem;
    line-height: 25px;
}
p.card-footer-description{
    font-size: var(--font-size-body);
}

.add-to-cart {
    margin-top: auto;
    padding: 1em 1em 1em 1em;
}

.card:hover .add-to-cart {
    color: rgb(56, 62, 58);
}
.card:hover .add-to-cart span {
    transform: translateX(5px);
}
.card:hover .card-image-container::before {
    transform: translateX(0) skewX(-10deg) scaleX(1.3);
}

.card:hover .card-footer {
    background: #ffffff;
    color: rgb(56, 62, 58);
}

.card:hover {
    transition: all 0.5s;
    background-position: 0%;
}
.slider{
    background: linear-gradient(to left, white 50%, var(--icon-background-color) 50%) right;
    background-size: 200% 100%;
    transition:all .5s ease;
    height: 5px;
    border-radius: var(--border-radius);
    margin-left: 3px;
    margin-right: 3px;
}

.card-hover-enter-done{
    background-position:left bottom;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
}
