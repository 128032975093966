.navbar {
  background-color: var(--nav-bar-color);
  padding: 0;
}

.login {
  margin: 1rem;
  padding: 10px;
  height: 3rem;
  width: 12rem;
  text-align: left;
}

.login-group > button {
  box-shadow: none;
  border-radius: 5px;
}

.login-google,
.login-twitter {
  border: 1px solid #aaa;
}

.login-facebook {
  background-color: #385499;
  color: #fff;
  border: 0;
}

.fa-square-twitter {
  color: #385499;
}

.login > * {
  vertical-align: middle;
}

.login > span {
  padding-left: 10px;
}

.fa-google-color-new {
  color: #4285f4 !important;
}
.navbar-nav {
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  align-items: center;
  justify-content: center;
}

.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.navbar-nav .icon-button {
  width: var(--button-size);
}
svg.hamburger{
  fill: none;
  transition: translate 1s, rotate 1s;
}

.button-hamburger .hamburger {
  transition: translate 1s, rotate 1s;
}

.button-hamburger[aria-expanded="true"] .hamburger {
  translate: 10px -10px;
  rotate: 0.125turn;
}
.button-hamburger[aria-expanded="true"] .hamburger path {
  stroke: var(--logo-fill-color)
}

.button-hamburger .line {
  transition: 1s;
  stroke-dasharray: 60 31 60 300;
}

.button-hamburger[aria-expanded="true"] .line {
  stroke-dasharray: 60 105 60 300;
  stroke-dashoffset: -90;
}

.icon-button.active svg {
  filter: brightness(2.6);
  fill: var(--logo-fill-color);
}
.icon-button:active, .icon-button:focus {
  filter: brightness(2.6);
}

.icon-button img {
  border-radius: 50%;
  width: 160%;
  max-width: 160%;
}

.hamburger{
  display: none;
}

.icon-button svg.hamburger .line  {
  transition:
          y 300ms ease-in 300ms,
          rotate 300ms ease-in,
          opacity 0ms 300ms;
  transform-origin: center;
}

.icon-button.active svg.hamburger .line  {
  transition:
    y 300ms ease-in,
    rotate 300ms ease-in 300ms,
  opacity 0ms 300ms;
}

.icon-button.active svg.hamburger :is(.top, .bottom){
  y:45;
}

.icon-button.active svg.hamburger .top{
  rotate: 45deg;
}

.icon-button.active svg.hamburger .middle{
  opacity: 0;
}

.icon-button.active svg.hamburger .bottom{
  rotate: -45deg;
}

.dropdown-upper-nav {
  top: 50px;
  right: 0;
}
.menu {
  width: 100%;
}

.menu-item {
  display: flex;
  align-items: center;
  border-radius: calc(0.7*var(--border-radius));
  transition: background 500ms;
  margin: 3px;
  padding: 0.5rem;
}
.nav-item.chevron-left {
  display: none;
}
.filter-container {
  position: sticky;
  top: 3.8em;
  align-self: flex-start;
}

.nav-item.chevron-left{
  display: none;
}

@media (max-width: 52em) {
  .navbar{
    padding-right: 0;
  }
  .nav-item.chevron-left {
    display: block;
  }
  .hamburger{
    display: block;
  }
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}
.menu-item:hover {
  background-color: var(--logo-background-color);
  color: var(--logo-fill-color);
}
.menu-item:hover svg {
  fill: var(--logo-fill-color);
}

