:root {
  --margin-web: 0 20%;
  --margin-mobile: .5rem;
  --mobile-size: 40em;
  --header-color: #7D6389;
  --nav-bar-color: #8EA489;
  --text-color-header: #BFB1C5;
  --text-color-body: #848484;
  --selected-color: #71415B;
  --logo-background-color: #50385C;
  --logo-fill-color: white;
  --backdrop-color: #BEB9C2;
  --icon-background-color: #50385C;
  --bullet-points-color: #EDF7D8;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
  --font-family-headings: "Quicksand", sans-serif;
  --font-family-body: "Work Sans", sans-serif;
  --font-family-monospace: "Roboto Mono", "Courier New", monospace;
  /*font sizes came from this artical https://enzedonline.com/en/tech-blog/create-responsive-font-sizes-based-on-the-viewport/*/
  --font-size-1: clamp(2rem, calc(1.7993rem + 0.8451vw), 2.75rem);
  --font-size-2: clamp(1.8rem, calc(1.6261rem + 0.7324vw), 2.45rem);
  --font-size-3: clamp(1.6rem, calc(1.4662rem + 0.5634vw), 2.1rem);
  --font-size-4: clamp(1.4rem, calc(1.293rem + 0.4507vw), 1.8rem);
  --font-size-5: clamp(1.2rem, calc(1.1331rem + 0.2817vw), 1.45rem);
  --font-size-6: clamp(0.9rem, calc(0.8732rem + 0.1127vw), 1rem);
  --font-size-body: clamp(1rem, calc(0.9599rem + 0.169vw), 1.15rem);
}
html {
  height: 100%
}
body {
  margin: 0;
  padding: 0;
  height: 100% ;
}
svg{
  cursor: pointer;
  fill: #2E4926
}
img{
  max-width: 100%;
}
h1{
  font-size: var(--font-size-1);
  font-weight: 300;
}
h2{
  margin: 0;
  font-size: var(--font-size-2);
  font-weight: 400;
  color: black;
}

body {
  margin: 0;
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  color: var(--text-color-body);
  background-color: var(--backdrop-color);

}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}
.cart-item{
  position: absolute;
  bottom: 10px;
  z-index: 1;
}

.send-to-cart.cart-item {
  display: block;
  animation: xAxis 4s forwards cubic-bezier(1, 0.44, 0.84, 0.165);
}

.send-to-cart.cart-item::before {
  content: "1";
  animation: yAxis 4s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}


@keyframes xAxis {
  100% {
    transform: translateX(calc(100vw - 150px));
  }
}

@keyframes yAxis {
  100% {
    transform: translateY(-104px);
  }
}